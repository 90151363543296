//面板影藏事件
const HIDE="hide";
//下拉面板显示
const SHOW="show";
//值改变事件
const ONCHANGE="comboTreeOnChange";

const updateKey='update:modelValue';
import {ref, computed, getCurrentInstance, reactive, toRefs, onBeforeMount,onMounted, defineComponent, watch, provide,nextTick} from 'vue';
import ComboTreeUtil,{IComboTreeDataObj} from "@/components/base_comp/comboTree/comboTreeUtil";
export default defineComponent({
    name: "comboTree",
    props: {
        modelValue: {
            type: String
        },
        disabled:{type:Boolean,default:false},
        bindEvent:{type:Object,default:()=>{return{}}}
    },
    setup(props,context){
        const {proxy} = getCurrentInstance() as any;
        const utils=proxy.utils;
        let comboParam:any=context.attrs.params;
        let dataObj:IComboTreeDataObj=reactive<IComboTreeDataObj>({
            utilInst:{}as any,
            refMap:new Map(),
            visible:false,
            readOnly:false,
            comboText: "",
            loading:false,

            mapTreeValueText:new Map(),
            isLoaded: false,
            modelMethod:comboParam.modelMethod,
            data: undefined,
            defaultProps: {
                label: 'label',
                children: 'children'
            },
            baseParams:{
                expandedKeys:[],
                expandAll:false,
                showCheckbox:false,
                checkOnClick:true,
                isOnlySelectLeaf:true
            },
            comboParam:comboParam,
            otherParams:{
                popoverWidth:400
            }
        })
        onBeforeMount(()=>{
            dataObj.utilInst=new ComboTreeUtil(proxy,dataObj,props,context)
            dataObj.utilInst.init(comboParam);
        })
        onMounted(()=>{
            nextTick(async()=>{
                if(props.modelValue && !dataObj.comboText){
                    await dataObj.utilInst.getTextById(props.modelValue,comboParam);
                    dataObj.baseParams.expandedKeys.push(props.modelValue);
                }
                let inputGroup:any=document.querySelector('.el-input-group');
                if(inputGroup && inputGroup.clientWidth && inputGroup.clientWidth>200){
                    dataObj.otherParams.popoverWidth=inputGroup.clientWidth;
                }
            })
        })
        watch(() => context.attrs.params,(newValue,oldValue) => {
            dataObj.utilInst.init(newValue);
        })
        watch(() => props.modelValue,async (newValue,oldValue) => {
            if(newValue){
                if(!dataObj.comboText)await dataObj.utilInst.getTextById(props.modelValue,comboParam);
                if(comboParam.formRef)await comboParam.formRef.validateField(comboParam.comboId);
            }else{
                dataObj.comboText = '';
            }
            context.emit(updateKey,newValue);
            await dataObj.utilInst.emitEvent(ONCHANGE, newValue, oldValue)
        })

        const getTreeInst=()=>{
            return dataObj.refMap.get('comboTreeRef');
        }

        const filterNode=(value:any, data:any)=>{
            if (!value) return true;
            return data.label.indexOf(value) !== -1;
        }

        const inputChange=(val:string)=>{
            dataObj.refMap.get('comboTreeRef').filter(val);
        }

        const handleNodeClick=(data:any)=>{

            if(!dataObj.baseParams.showCheckbox){

                if(dataObj.baseParams.isOnlySelectLeaf) {
                    if (!data.children) {
                        dataObj.comboText=data.label;
                        context.emit(updateKey,data.value);
                        dataObj.refMap.get('input').$el.click();
                    }
                }else{
                    dataObj.comboText=data.label;
                    context.emit(updateKey,data.value);
                    dataObj.refMap.get('input').$el.click();
                }
            }
        }
        const checkChange=(data:any, checked:any, indeterminate:any)=>{
            if(!dataObj.baseParams.showCheckbox)return;
            if(checked){
                if(!dataObj.mapTreeValueText.has(data.value)){
                    if(dataObj.baseParams.isOnlySelectLeaf){
                        if(!data.children){
                            dataObj.mapTreeValueText.set(data.value,data.label);
                        }
                    }else{
                        dataObj.mapTreeValueText.set(data.value,data.label);
                    }
                }
            }else{
                if(dataObj.mapTreeValueText.has(data.value))dataObj.mapTreeValueText.delete(data.value);
            }

            dataObj.comboText=[...dataObj.mapTreeValueText.values()].join(',');
            context.emit(updateKey,[...dataObj.mapTreeValueText.keys()].join(','));
        }

        const inputClear=()=>{

            if(dataObj.baseParams.showCheckbox)dataObj.mapTreeValueText.clear();

            dataObj.comboText='';
            context.emit(updateKey,'');
        }

        const hide=async ()=>{
            await dataObj.utilInst.emitEvent(HIDE)
        }

        const show=async()=>{
            dataObj.loading=true;
            if(!dataObj.data){
                let modelPath=dataObj.modelMethod+ "/custom";
                let res = await utils.Api.postRequest({ url: modelPath, params: Object.assign({operateType:'tree'},dataObj.baseParams) });
                dataObj.data=res.data;
            }

            if(dataObj.baseParams.showCheckbox){
                if(props.modelValue){

                    let _modelValue:any=props.modelValue;
                    dataObj.refMap.get('comboTreeRef').setCheckedKeys(_modelValue.split(","));
                }else{
                    dataObj.refMap.get('comboTreeRef').setCheckedKeys([]);
                }
            }
            await dataObj.utilInst.emitEvent(SHOW)//触发事件
            if (props.modelValue) {
                dataObj.baseParams.expandedKeys.length=0;
                dataObj.baseParams.expandedKeys.push(props.modelValue);
            }
            dataObj.loading=false;//关闭正在加载效果
        }
        return{
            ...toRefs(dataObj),getTreeInst,filterNode,inputChange,
            handleNodeClick,checkChange,inputClear,hide,show
        }
    },

    components: {}
});